@font-face {
  font-family: 'ProximaNovaBold';
  src: url(../fonts/Proxima-Nova-Bold.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url(../fonts/Proxima-Nova-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNovaMedium';
  src: url(../fonts/Proxima-Nova-Medium.woff) format('woff');
}

@font-face {
  font-family: 'ProximaNovaRegular';
  src: url(../fonts/Proxima-Nova-Regular.woff) format('woff');
}
